import {
  errorServiceHandler,
  makeQueryStringDync,
  makeQueryFormDataDyn,
  toFormUrlEncoded,
  errorHandlerMiddleware,
  setHeader,
} from "../../helper";

const actions = {
  createProfile: (context, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      let data = toFormUrlEncoded(payload);
      fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/profile`, {
        method: "POST",
        body: data,
        headers: setHeader("formUrl"),

        // {
        //   "Content-Type": "application/x-www-form-urlencoded",
        //   "X-SESSION": `${localStorage.getItem("subscriberToken")}`
        // }
      })
        .then((response) => errorHandlerMiddleware(response, () => actions.createProfile(context, payload)))
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateSubscriber: (context, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/subscriber`, {
        method: "PUT",
        body: JSON.stringify(payload),
        headers: setHeader("json"),

        // {
        //   "Content-Type": "application/json",
        //   "X-SESSION": `${localStorage.getItem("subscriberToken")}`
        // }
      })
				.then((response) => errorHandlerMiddleware(response, () => actions.updateSubscriber(context, payload)))
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fileUpload: (context, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      let data = makeQueryFormDataDyn(payload);
      fetch(`${context.getters.vSmsBaseUrl}image/v1/upload`, {
        method: "POST",
        body: data,
        headers: setHeader(),

        // {
        //   "X-SESSION": `${localStorage.getItem("subscriberToken")}`
        // }
      })
        .then(errorHandlerMiddleware)
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchTicketAttachemnets: (context, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      // let data = makeQueryStringDync(payload);
      fetch(`${context.getters.vCrmBaseUrl}subscriber/v1/request/comment/attachment/${payload.requestid}?attachmentid=${payload.attachmentid}&messageid=${payload.messageid}`, {
        method: "GET",
        headers: setHeader(),

        // {
        //   "X-SESSION": `${localStorage.getItem("subscriberToken")}`
        // }
      })
        .then(errorHandlerMiddleware)
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  
  listProfiles: (context) => {
    return new Promise((resolve, reject) => {
      fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/profile`, {
        method: "GET",
        headers: {
            "X-SESSION": `${localStorage.getItem("sessionToken")}`
          }
        
        // setHeader(),

        // {
        //   "X-SESSION": `${localStorage.getItem("subscriberToken")}`
        // }
      })
				.then((response) => errorHandlerMiddleware(response, () => actions.listProfiles(context)))
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  switchProfile: (context, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/profile/${payload.profileid}`, {
        method: "PUT",
        body: payload.pin ? JSON.stringify(payload.pin) : "",
        headers: setHeader("json"),

        // {
        //   "Content-Type": "application/json",
        //   "X-SESSION": `${localStorage.getItem("subscriberToken")}`
        // }
      })
				.then((response) => errorHandlerMiddleware(response, () => actions.switchProfile(context, payload)))
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
	},
	pairDevice: (context, payload) => {
		return new Promise((resolve, reject) => {
			if (!payload) {
				reject({ error: "payload not found" });
			}
			let data = toFormUrlEncoded(payload);
			fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/profile/pairdevice`, {
				method: "POST",
				body: data,
				headers: setHeader("formUrl"),
				// headers: setHeader("json"),
				// headers: {
				//   "X-SESSION": `${localStorage.getItem("sessionToken")}`,
				// },
				// {
				//   "Content-Type": "application/json",
				//   "X-SESSION": `${localStorage.getItem("subscriberToken")}`
				// }
			})
				.then((response) => errorHandlerMiddleware(response, () => actions.ResetProfilePin(context, payload)))
				.then((data) => {
					resolve({ data });
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
  deleteProfile: (context, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/profile/${payload.profileid}`, {
        method: "DELETE",
        headers: setHeader(),

        // {
        //   "X-SESSION": `${localStorage.getItem("subscriberToken")}`
        // }
      })
        .then(errorHandlerMiddleware)
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deviceList: (context, payload) => {
    let params;

    if(payload) {
      params = makeQueryStringDync(payload);
    }

    return new Promise((resolve, reject) => {
      fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/device${params ? params : ""}`, {
        method: "GET",
        headers: setHeader(),
      })
        .then(errorHandlerMiddleware)
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  
  updateDevice: (context, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      let bodyPayload = JSON.stringify(payload.status);
      fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/device/${payload.deviceId}`, {
        method: "PUT",
        body: bodyPayload,
        headers: setHeader("json"),
      })
        .then(errorHandlerMiddleware)
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  listPurchase: (context) => {
    return new Promise((resolve, reject) => {
      // if (!payload) {
      //   reject({ error: "payload not found" });
      // }
      // let data = makeQueryStringDync(payload);
      fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/purchase`, {
        method: "GET",
        headers: setHeader(),
        // {
        //   Authorization: `Bearer ${localStorage.getItem("subscriberToken")}`
        // }
      })
        .then((response) => response.json())
        .then((data) => {
          resolve({ data });
        })
        .catch(errorHandlerMiddleware)
        .catch((error) => {
          reject(error);
        });
    });
  },
  listBill: (context, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      let data = makeQueryStringDync(payload);
      fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/bill${data}`, {
        method: "GET",
        headers: setHeader(),

        // {
        //   Authorization: `Bearer ${localStorage.getItem("subscriberToken")}`
        // }
      })
        .then((response) => response.json())
        .then((data) => {
          resolve({ data });
        })
        .catch(errorHandlerMiddleware)
        .catch((error) => {
          reject(error);
        });
    });
  },
  billDetails: (context, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/bill/${payload}`, {
        method: "GET",
        headers: setHeader(),

        // {
        //   Authorization: `Bearer ${localStorage.getItem("subscriberToken")}`
        // }
      })
        .then(errorHandlerMiddleware)
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  downloadBills: (context, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/bill/${payload}`, {
        method: "GET",
        headers: setHeader(),

        // {
        //   Authorization: `Bearer ${localStorage.getItem("subscriberToken")}`
        // }
      })
        .then(errorHandlerMiddleware)
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  listTickets: (context, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      let data = makeQueryStringDync(payload);
      fetch(`${context.getters.vCrmBaseUrl}subscriber/v1/request${data}`, {
        method: "GET",
        headers: setHeader(),

        // {
        //   "X-SESSION": `${localStorage.getItem("subscriberToken")}`
        // }
      })
        .then(errorHandlerMiddleware)
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getTicket: (context, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      fetch(`${context.getters.vCrmBaseUrl}subscriber/v1/request/${payload.requestid}`, {
        method: "GET",
        headers: setHeader(),

        // {
        //   "X-SESSION": `${localStorage.getItem("subscriberToken")}`
        // }
      })
        .then(errorHandlerMiddleware)
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createTicket: (context, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      // let data = JSON.stringify(payload);
      let urlEncode = toFormUrlEncoded(payload);
      console.log("data", urlEncode);
      fetch(`${context.getters.vCrmBaseUrl}subscriber/v1/request`, {
        method: "POST",
        body: urlEncode,
        headers: setHeader("formUrl"),

        // {
        //   "Content-Type": "application/x-www-form-urlencoded",
        //   "X-SESSION": `${localStorage.getItem("subscriberToken")}`
        // }
      })
        .then(errorHandlerMiddleware)
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateTicket: (context, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      let raw = JSON.stringify(payload.details);
      // console.log("raw", raw);
      fetch(`${context.getters.vCrmBaseUrl}subscriber/v1/request/${payload.requestid}`, {
        method: "PUT",
        body: raw,
        headers: setHeader("json"),

        // {
        //   "Content-Type": "application/json",
        //   "X-SESSION": `${localStorage.getItem("subscriberToken")}`
        // }
      })
        .then(errorHandlerMiddleware)
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  listPlans: (context, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      let data = payload ? makeQueryStringDync(payload) : "";
      fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/plan${data}`, {
        method: "GET",
        headers: setHeader(),

        // {
        //   Authorization: `Bearer ${localStorage.getItem("subscriberToken")}`
        // }
      })
        .then((response) => response.json())
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getPlan: (context, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/plan/${payload.planid}`, {
        method: "GET",
        headers: setHeader(),

        // {
        //   "X-SESSION": `${localStorage.getItem("subscriberToken")}`
        // }
      })
        .then((response) => response.json())
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  listAllSubscription: (context, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      let data = makeQueryStringDync(payload);
      fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/subscription${data}`, {
        method: "GET",
        headers: setHeader(),

        // {
        //   "X-SESSION": `${localStorage.getItem("subscriberToken")}`
        // }
      })
        .then(errorHandlerMiddleware)
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // Subscription Precheck - config endponit
  subscriptionPrecheck: (context, payload) => {
    console.log("SUB PRECHECK ---", context.getters.appConfig.apiUtilities.subscriptionPrecheck);

    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      let data = makeQueryStringDync(payload);
      fetch(
        `${context.getters.vSmsBaseUrl}${
          context.getters.appConfig.apiUtilities.subscriptionPrecheck
            ? context.getters.appConfig.apiUtilities.subscriptionPrecheck
            : "subscriber/v1"
        }/subscription/precheck${data}`,
        {
          method: "GET",
          headers: setHeader(),

          // {
          //   "X-SESSION": `${localStorage.getItem("subscriberToken")}`
          // }
        }
      )
        .then(errorHandlerMiddleware)
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // Create Subscription - From Config endpoint
  createSubscription: (context, payload) => {
    console.log("THE CREATE SUB -----", context.getters.appConfig.apiUtilities.createSubscription);

    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      let urlEncode = toFormUrlEncoded(payload);
      fetch(
        `${context.getters.vSmsBaseUrl}${
          context.getters.appConfig.apiUtilities.createSubscription
            ? context.getters.appConfig.apiUtilities.createSubscription
            : "subscriber/v1"
        }/subscription`,
        {
          method: "POST",
          body: urlEncode,
          headers: setHeader("formUrl"),

          // {
          //   "Content-Type": "application/x-www-form-urlencoded",
          //   "X-SESSION": `${localStorage.getItem("subscriberToken")}`
          // }
        }
      )
        .then(errorHandlerMiddleware)
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // Subscription update - config endpoint
  subscriptionUpdate: (context, payload) => {
    console.log("SUBSCRIPTION UPDATE LOG --- ", context.getters.appConfig.apiUtilities.updateSubscription);

    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      fetch(
        `${context.getters.vSmsBaseUrl}${
          context.getters.appConfig.apiUtilities.updateSubscription
            ? context.getters.appConfig.apiUtilities.updateSubscription
            : "subscriber/v1"
        }/subscription/${payload.planid}`,
        {
          method: "PUT",
          body: JSON.stringify(payload.params),
          headers: setHeader("json"),

          // {
          //   "Content-Type": "application/json",
          //   "X-SESSION": `${localStorage.getItem("subscriberToken")}`
          // }
        }
      )
        .then(errorHandlerMiddleware)
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  availabilityList: (context, payload) => {
    return new Promise((resolve, reject) => {
      fetch(
        `${context.getters.vSmsBaseUrl}subscriberv2/v1/availability?page=${payload.page}&pagesize=${payload.pagesize}`,
        {
          method: "GET",
          headers: setHeader(),

          // {
          //   Authorization: `Bearer ${localStorage.getItem("subscriberToken")}`
          // }
        }
      )
        .then(errorHandlerMiddleware)
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  availabilityDetail: (context, availabilityid) => {
    return new Promise((resolve, reject) => {
      fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/availability/${availabilityid}`, {
        method: "GET",
        headers: setHeader(),

        // {
        //   Authorization: `Bearer ${localStorage.getItem("subscriberToken")}`
        // }
      })
        .then(errorHandlerMiddleware)
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // Purchase precheck - Config endpoint
  purchasePrecheck: (context, payload) => {
    console.log("THE PURCHASE PRECHECK -----", context.getters.appConfig.apiUtilities.purchasePrecheck);

    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      let data = makeQueryStringDync(payload);
      fetch(
        `${context.getters.vSmsBaseUrl}${
          context.getters.appConfig.apiUtilities.purchasePrecheck
            ? context.getters.appConfig.apiUtilities.purchasePrecheck
            : "subscriber/v1"
        }/purchase/precheck${data}`,
        {
          method: "GET",
          headers: setHeader(),

          // {
          //   "X-SESSION": `${localStorage.getItem("subscriberToken")}`
          // }
        }
      )
        .then(errorHandlerMiddleware)
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // Create purchase - config endpoint
  purchaseCreate: (context, payload) => {
    console.log("PURCHASE CREATE CONFIG --- ", context.getters.appConfig.apiUtilities.createPurchase);

    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      let urlEncode = toFormUrlEncoded(payload);
      fetch(
        `${context.getters.vSmsBaseUrl}${
          context.getters.appConfig.apiUtilities.createPurchase
            ? context.getters.appConfig.apiUtilities.createPurchase
            : "subscriber/v1"
        }/purchase`,
        {
          method: "POST",
          body: urlEncode,
          headers: setHeader("formUrl"),

          // {
          //   "Content-Type": "application/x-www-form-urlencoded",
          //   "X-SESSION": `${localStorage.getItem("subscriberToken")}`
          // }
        }
      )
        .then(errorHandlerMiddleware)
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  actGetTvPin: (context, payload) => {
    return new Promise((resolve, reject) => {
      if (!payload) {
        reject({ error: "payload not found" });
      }
      let urlEncode = toFormUrlEncoded(payload);
      fetch(`${context.getters.vSmsBaseUrl}subscriberv2/v1/device/pin`, {
        method: "POST",
        body: urlEncode,
        headers: setHeader("formUrl"),

        // {
        //   "Content-Type": "application/x-www-form-urlencoded",
        //   "X-SESSION": `${localStorage.getItem("subscriberToken")}`
        // }
      })
        .then(errorHandlerMiddleware)
        .then((data) => {
          resolve({ data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  actions,
};
