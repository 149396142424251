import { store } from "@/store/store";
import { eventBus } from "@/eventBus";

const Common = {
  methods: {
    checkAvailabilityForClevertap(content) {
      if (content.hasOwnProperty("contentdetails") && content.contentdetails.length > 0) {
        this.filterAvailabilities(content);

        this.filteredAvailabilities.forEach(element => {
          this.pricemodel = element.pricemodel;
        });
        
        return this.pricemodel;
      }
    },
    checkAvailability(content, isDetailCard) {
      //empty filteredavailabilities array.
      this.filteredAvailabilities = [];
      //empty pricemodel array.
      this.pricemodel = [];

      //is the control coming from detail card
      if (isDetailCard) {
        //Do the button display check.
        if (content.hasOwnProperty("contentdetails") && content.contentdetails.length > 0) {
          this.isButtonEnable = true;

          //Here do the availablity check.
          this.filterAvailabilities(content);

          this.filteredAvailabilities.forEach(element => {
            this.pricemodel.push(element.pricemodel);
          });

          console.log("price model", this.pricemodel);

          //based on pricemodel of availabilities assigning buttonstatus.
          if (this.pricemodel.includes("PLAN") && this.pricemodel.includes("PAID") || this.pricemodel.includes("RENTAL")) {
            this.buttonStatus = 'SUBSCRIBE OR PURCHASE';
          } else if (this.pricemodel.includes("PLAN")) {
            this.buttonStatus = "SUBSCRIBE & WATCH";
          } else {
            this.buttonStatus = "PURCHASE & WATCH";
          }

          //checking the plan || (paid || rental) availability is playable or not.
          if (this.checkAvailablitiyisPlayable(content)) {
            this.isButtonEnable = true;
            this.buttonStatus = "PLAY NOW";
          }

        } else if (content.hasOwnProperty("trailer") && content.trailer.length > 0) {
          this.isButtonEnable = true;
          this.buttonStatus = "PLAY NOW";
          content.videoType = 'Trailer';
        } else {
          content.isButtonEnable = false;
        }
      }
      //control coming from other place. 
      else {
        //Here do the availablity check.
        this.filterAvailabilities(content);
        //if the availability matches any of the type
        if (this.checkAvailablitiyisPlayable(content)) {
          let payload = {
            content: content,
            state: true
          };
          console.log("player payload", payload);
          eventBus.$emit("togglePlayer", payload);
        }
      }
    },
    filterAvailabilities(content) { 
      let content_details = content.contentdetails[0];

      content_details.availabilityset.forEach(availability => {
        let index = this.availabilities.findIndex(element => {
          return element.availabilityid === availability;
        });
        if (index > -1) {
          // this.pricemodel.push(this.availabilities[index].pricemodel)
          this.filteredAvailabilities.push(this.availabilities[index]);
        }
      });
      console.log("filtered", this.filteredAvailabilities);
    },
    checkAvailablitiyisPlayable(content) {
      console.log("test");
      let content_details = content.contentdetails[0];
      let content_Playable = false;

      this.filteredAvailabilities.forEach(element => {
        //check avalabilty belongs to subscribed list.
        if (element.pricemodel === "PLAN") {
          if (this.subscriberid && this.subscriptions.length > 0) {
            this.subscriptions.forEach(subscription => {
              let value = subscription.availabilityset.includes(
                element.availabilityid
              );
              if (value && !content_Playable) {
                content.isDrmContent = true;
                content.videoType = 'Content';
                content.playback_details = {
                  packageid: content_details.packageid,
                  availabilityid: element.availabilityid
                };
                content_Playable = true;
              }
            });
          }
          
        }
        //check availability belongs to purchased list.
        else if (
          element.pricemodel === "PAID" ||
          element.pricemodel === "RENTAL"
        ) {
          if (this.subscriberid && this.purchases.length > 0) {
            //write purchase check logic.
            this.purchases.forEach(purchase => {
              if (
                purchase.availabilityid === element.availabilityid &&
                this.content.objectid === purchase.objectid
              ) {
                if (!content_Playable) {
                  content.isDrmContent = true;
                  content.videoType = 'Content';
                  content.playback_details = {
                    packageid: content_details.packageid,
                    availabilityid: element.availabilityid
                  };
                  content_Playable = true;
                }
              }
            });
          }
        }
        //check availability belongs to free or adbased pricemodel.
        else if (
          element.pricemodel === "FREE" ||
          element.pricemodel === "ADBASED"
        ) {
          if (!content_Playable) {
            content.isDrmContent = false;
            content.videoType = 'Content';
            //creating this playback_details for content playback.
            content.playback_details = {
              packageid: content_details.packageid,
              availabilityid: element.availabilityid
            };
            content_Playable = true;
          }
        }
      });

      if (content_Playable) {
        return true;
      } else {
        return false;
      }
    }
  }
};

export default Common;